import React from 'react'
import { css } from '@emotion/react' 

const Stylesheet = () => {
	return(
		<div css={css`padding: 3rem;`}>
			<h1 className='large'>Heading 1 (Large)</h1>
			<h1 className='large numbers'>Heading 1 (Large Numbers)</h1>
			<h1>Heading 1</h1>
			<h1 className='numbers'>Heading 1 (Numbers)</h1>
			<h2>Heading 2</h2>
			<h3>Heading 3</h3>
			<h3 className='numbers'>Heading 3 (Numbers)</h3>
			<h4>Heading 4</h4>
			<p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet purus id libero pulvinar, eu vehicula enim porta. Vivamus et massa euismod, ornare neque porttitor, dignissim lorem. Aenean sodales, arcu et scelerisque ornare, nisi velit faucibus nisl, ac interdum turpis nunc cursus nibh. Quisque eleifend consectetur lectus, a fringilla orci ultricies et.
			</p>
		</div>
	)
}

export default Stylesheet